import React from "react"
import PropTypes from "prop-types"
import { Helmet } from 'react-helmet'
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Robot from "../components/robot"

const NotFoundPage = (props) => (
	<>
		<Helmet
			bodyAttributes={{
				class: "page-not-found"
			}}
		/>
		<Layout location={props.location.pathname}>
			<SEO title="404: Not found" />
			<Robot />
			<div className="container">
				<h1>Oops, you broke the internet</h1>
				<p>Just kidding. But it seems Robo, your personal robot, can&apost find the page you&aposre looking for.
				Please make sure you&aposve entered the correct address, or return to <Link to="/"> Home</Link>.
				</p>
			</div>
		</Layout>
	</>
)

NotFoundPage.propTypes = {
	location: PropTypes.object
}

export default NotFoundPage
